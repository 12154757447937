@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}
@function rem-calc($size) { $remSize: $size / 16; @return #{$remSize}rem; }
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio); // IE9 only
  -o-transform: scale($ratio);
  transform: scale($ratio);
}
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
  -webkit-box-shadow:$top $left $blur $color #{$inset};
  -moz-box-shadow:$top $left $blur $color #{$inset};
  box-shadow:$top $left $blur $color #{$inset};
}

@mixin linear-gradient($from, $start, $to, $end) {
  background: $to;
  background: -moz-linear-gradient(top, $from $start, $to $end);
  background: -webkit-gradient(linear, left top, left bottom, color-stop($start,$from), color-stop($end,$to));
  background: -webkit-linear-gradient(top, $from $start,$to $end);
  background: -o-linear-gradient(top, $from $start,$to $end);
  background: linear-gradient(to bottom, $from $start,$to $end);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}
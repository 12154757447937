figure.figure-progressive {
  //background-color: $white;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding-bottom: 75%;
  margin: 0;
  &.figure-square {
    padding-bottom: 100%;
  }
  &.figure-landscape {
    padding-bottom: 65.1%;
  }
  &.figure-tile {
    padding-bottom: 140%;
  }
  &.figure-team-content {
    padding-bottom: 49%;
  }
  img {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.05s linear;
    &.loaded {
      opacity: 1;
    }
  }
  .figure-img {
    width: 100%;
  }
  .img-small {
    filter: blur(50px);
    transform: scale(1);
  }
}

figcaption {
  position: absolute;
  bottom: 24px;
  padding: 0 10px;
  width: 100%;
  color: $white;
  font-size: $h1-font-size;
  line-height: $headings-line-height*1.1;
  font-weight: 300;
  text-align: center;
  z-index: 9;
}

.figure-label {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 30px;
  margin: 1.4rem;
  background-color: #fff;
  height: 110px;
  width: 110px;
  display: flex;
  color: palette(red, base);
  //font-family: $font-family-addition;
  font-size: $font-size-base*1.16725;
  font-weight: $font-weight-normal;
  line-height: 1.5rem;
  align-items: center;
  z-index: 400;
  border-radius: 50%;
}

.item-image {
  margin: 1rem auto;
  &--max-width-110 {
    //margin: 1rem auto;
    max-width: 110px;
  }
  &--max-width-210 {
    //margin: 2rem auto;
    max-width: 210px;
  }
}

.site-content {
  figure {
    margin-bottom: 2rem;
  }
}
nav {
    ul.pagination {
        margin-bottom: 1.5rem;
    }
    li.page-item {
        margin: 0 0.5rem;
        a {
            display: inline-block;
            padding: 0;
            line-height: 1.8;
            font-weight: 400;
            text-align: center;
            border-radius: 0!important;
        }
    }
    li.page-item.active {
        .page-link {
            color: $primary;
            &:after {
                content: "";
                background-color: $primary;
                height: 2px;
                width: 10px;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
    }
}

//custom arrow
.custom-arrow {
    display: inline-block;
    float: left;
    height: 34px;
    width: 34px;
    border: 1px solid $primary;
    border-radius: 50%;
    position: relative;
    vertical-align: middle;

    &--left {
        transform: rotate(45deg);
    }
    &--right {
        transform: rotate(225deg);
    }

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $white;
        content: "";
        width: 12px;
        height: 12px;
        transform: translate(-2px, -9px);
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $primary;
        content: "";
        width: 12px;
        height: 12px;
        transform: translate(-4px, -7px);
    }
}

a:hover {
    .custom-arrow {
        background-color: $primary;
        &:after {
            background-color: $primary;
        }
        &:before {
            background-color: $white;
        }
    }
}
html {
  font-size: 0.85rem;

  .display-1 {
    font-size: 1.85rem;
  }
}

@include media-breakpoint-up(sm) {
  html {
    //font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
    .display-1 {
      font-size: 2.75rem;
    }
  }
}
//
//@include media-breakpoint-up(xl) {
//  html {
//    font-size: $font-size-lg;
//  }
//
//
//}
.site-header {
  position: relative;
  min-height: 70px;
  margin: 0 auto;
  max-width: 1920px;
  .col {
    min-height: 0;
  }
  .site-header-heading {
    width: 100%;
    height: 100%;
    z-index: 1;
    .site-header-title {
      font-size: rem-calc(30);
      font-weight: $font-weight-light;
      margin-bottom: 1.5rem;
    }
    .site-header-description {
      font-size: rem-calc(21);
      font-weight: $font-weight-light;
      margin-bottom: 2.625rem;
    }
  }
  figure {
    margin: 0;
    &.figure-progressive.figure-homepage {
      padding-bottom: 45%;
    }
    &.figure-progressive.figure-contentpage {
      padding-bottom: 28.2%;
    }
    .figure-img{
      margin: 0;
    }
  }
  .site-header-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: rem-calc(14);
    text-transform: uppercase;
    z-index: 9;
  }
}

@include media-breakpoint-up(lg) {
  .site-header {
    .site-header-heading {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: flex-start;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#153f73+60,153f73+100&0+60,0.2+100 */
      background: -moz-linear-gradient(top, rgba(21, 63, 115, 0) 60%, rgba(21, 63, 115, 0.2) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(21, 63, 115, 0) 60%, rgba(21, 63, 115, 0.2) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(21, 63, 115, 0) 60%, rgba(21, 63, 115, 0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00153f73', endColorstr='#33153f73', GradientType=0); /* IE6-9 */

      .site-header-title {
        display: block;
        font-size: rem-calc(30);
      }
      .site-header-description {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .site-header {
    .site-header-heading {
      .site-header-title {
        font-size: rem-calc(34);
      }
      .site-header-description {
        font-size: rem-calc(24);
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .btn-contact {
    position: fixed;
    left: 18px;
    bottom: 0;
    padding: .475rem 1rem!important;
  }
}

.social-wrapper {
  margin-bottom: 10px;
}
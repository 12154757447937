.download-button {
  text-align: center;
  i {
    background-color: #006183;
    display: inline-block;
    width: 64px;
    height: 64px;
    color: #fff;
    font-size: 30px;
    line-height: 64px;
    border-radius: 50%;
    text-shadow: 0 4px 10px rgba(0,0,0,0.2);
  }
}
@include media-breakpoint-up(md) {
  .download-button {
    i {
      width: 84px;
      height: 84px;
      font-size: 40px;
      line-height: 84px;
    }
  }
}
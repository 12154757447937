section {
  h1 {
    font-size: $h2-font-size;
  }
}
section#news-overview {
  .tile {
    .tile--content {
      display: block;
      border-bottom: 1px solid rgba($brand-primary, 0.2);
    }
  }
}
//Brand colors
$brand-primary: #000000;

$primary:       #006183;

$palettes: (

        blue: (
                base: #0056a4,
                light: #26B3E8
        ),

        red: (
                base: #931f2f,
                light: #b90738
        ),

        green: (
                base: #00B1B2
        ),



        pink: (
                base: #dd006d
        ),

        purple: (
                base: #A881B9
        ),

        yellow: (
                base: #f9b626
        ),

        orange: (
                base: #F39200
        ),

        grey: (
                base: #acacac,
                light: #f3f2f1,
                dark: #434343,
                darker: #3c3c3b
        ),

        white: (
                base: #ffffff
        )

);

$theme-colors: () !default;
$theme-colors: map-merge((
        "primary": $primary,
        "secondary": rgba($primary,0.5)
), $theme-colors);
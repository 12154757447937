form {
  margin-bottom: 2rem;
}

.custom-checkbox {
  position: relative;
  //flex-direction: column;
  .btn {
    padding: 0;
    border-left: 0!important;
    font-size: $font-size-base*0.875;
    font-weight: $font-weight-normal;
    text-align: left;
    text-transform: inherit;
    &.focus {
      outline: none;
      box-shadow: none;
    }
  }
  span.indicator-custom {
    width: 16px;
    height: 16px;
    color: #fff;
    background-color: transparent;
    border: 2px solid #aaa;
    border-radius: 3px;
    padding: 0;
    margin-right: 7px;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      transition: .3s;
      -webkit-transform: rotateZ(90deg) scale(0);
      transform: rotateZ(90deg) scale(0);
      width: 10px;
      border-bottom: 4px solid #aaa;
      border-right: 4px solid #aaa;
      border-radius: 3px;
      top: -2px;
      left: 2px;
    }
  }
  .btn:not(:disabled):not(.disabled).active{
    span.indicator-custom {
      //background-color: red;
    }
    span.indicator-custom:after {
      -webkit-transform: rotateZ(45deg) scale(1);
      transform: rotateZ(45deg) scale(1);
      top: -6px;
      left: 5px;
    }
  }
  .btn:not(:disabled):not(.disabled).focus{
    span.indicator-custom {
      border: 1px solid #04beff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0,97,131,0.25);
    }
  }
  span.radio-text {
    //position: absolute;
    color: $body-color;
    //top: 4px;
    //left: 28px;
    //width: 100%;
    //text-align: left;
  }
}
.author {
  .author-image{
    display: inline-block;
    width: 60px;
    margin-right: 10px;
    vertical-align: middle;
    float: left;
  }

  .post-meta {
    display: inline;
    .post-name {

    }
    .post-date {

    }
  }
}
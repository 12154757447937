.logo {
  padding: 7vw 0 2vw;
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    //min-width: 250px;
  }
}

@include media-breakpoint-up(md) {
  .logo {
    img {
      width: 280px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .logo {
    padding: 7vw 0 2vw;
  }
}

@include media-breakpoint-up(xl) {
  .logo {
    padding: 7vw 0 4vw;
    img {
      width: 330px;
    }
  }
}

/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
    .logo {
      img {
        width: 330px;
      }
    }
}
footer {
    display: flex;
    align-items: center;
    h6 {
        margin-bottom: 0;
        font-weight: 700;
        line-height: $line-height-base;
        white-space: nowrap;
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            a {
                color: $body-color;
                white-space: nowrap;
            }
        }
    }

    &.sticky {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

@include media-breakpoint-up(sm) {

}
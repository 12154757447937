.slider {
  margin-bottom: 40px;
  .item {
    .block {
      height: 100%;
    }
    figure {
      height: 100%;
      padding-bottom: 115%;

      &.figure-tile {
        &--big {
          padding-bottom: 57%;
        }
      }
    }
    .item--left {
      width: 63%;
      float: left;

      border-right: 5px solid $white;
    }
    .item--right {
      width: 37%;
      float: left;

      border-left: 5px solid $white;
    }
    .item-content, .more {
      position: relative;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .item-content {
      padding: $spacer*2;
      color: $white;

      h2 {
        font-size: 30px;
        line-height: 28px;

        text-shadow: 3px 3px 7px rgba($black,0.2);

        -webkit-transform: translate(-20px);
        transform: translate(-20px);

        -webkit-transition: all 1200ms 0ms ease;
        transition: all 1200ms 0ms ease;
      }
    }

    .more {
      a {
        position: relative;
        padding: $spacer/2 $spacer;
        border: 1px solid;
        display: inline-block;

        transition: all 200ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
        text-decoration: none;
      }

      &:hover {
        a {
          background-color: $brand-primary;
          color: $white;
          border-color: $brand-primary;
        }
      }
    }
  }
}

.owl-carousel .owl-stage {
  padding-left: 0!important;
  &.no-offset {
    margin-left: 0;
  }
}

.owl-nav {
  //height: 80px;
  span {
    display: inline-block;
    height: 68px;
    width: 40px;
    color: $white;
  }
  .owl-next, .owl-prev {
    position: relative;
    outline: none;
    &:before {
      content: "";
      background-color: #006183;
      display: inline-block;
      font-size: 24px;
      font-weight: 300;
      width: 1px;
      height: 22px;
      vertical-align: middle;
      transform: rotate(-45deg);
      position: absolute;
      right: 50%;
      top: 50%;
      margin-top: -19px;
    }
    &:after {
      content: "";
      background-color: #006183;
      display: inline-block;
      font-size: 24px;
      font-weight: 300;
      width: 1px;
      height: 22px;
      vertical-align: middle;
      transform: rotate(45deg);
      position: absolute;
      right: 50%;
      top: 50%;
      margin-top: -3px;
    }
    &.disabled {
      outline: none;
      span {
        cursor: default;
      }
      &:before,&:after {
        background-color: #dddddd;
        cursor: default;
      }
    }
  }
  .owl-next {
    float: right;
    transform: translateX(-42px);
  }
  .owl-prev {
    transform: translateX(42px);
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.owl-dots {
  counter-reset: slides-num;
  position: absolute;
  bottom: 2px;
  right: 115px;
  height: 63px;
  width: 86px;
  padding: 14px 17px;
  border: 1px solid $primary;
  border-radius: $border-radius;

  &:before {
    content: "";
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    background-color: $primary;
    width: 1px;
    height: 22px;
    vertical-align: middle;
    transform: rotate(22deg);
    position: absolute;
    right: 50%;
    top: 50%;
    margin-top: -10px;
  }

  &:after {
    content: counter(slides-num);
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    color: $primary;
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 22px;
  }
}

.owl-dot {
  display: inline-block;
  counter-increment: slides-num;
  margin-right: 5px;
  span {
    display: none;
  }
  &:before {
    content: "";
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    color: $primary;
    vertical-align: middle;
    position: absolute;
    top: 10px;
    left: 22px;
  }
  &.active {
    &:before {
      content:  counter(slides-num);
    }
  }
}

@include media-breakpoint-up(sm) {
  .owl-carousel .owl-stage {
    margin-left: calc((100% - 540px)/2);
    margin-right: 100px!important;;
  }
}

@include media-breakpoint-up(md) {
  .owl-carousel .owl-stage {
    margin-left: calc((100% - 720px)/2);
  }
}

@include media-breakpoint-up(lg) {
  .owl-carousel .owl-stage {
    margin-left: calc((100% - 960px)/2);
  }
}

@include media-breakpoint-up(xl) {
  .owl-carousel .owl-stage {
    margin-left: calc((100% - 1140px)/2);
  }
}

@media (max-width: 1920px) {
  .container-custom {
    //max-width: none;
    padding: 0;
  }
}
@media (min-width: 1921px) {
  .owl-carousel .owl-stage {
    margin-left: 0;
  }
  .container-custom {
    //max-width: 1920px;
    padding: 0;
  }
}

body.loaded {
  .slider {
    .item {
      .item-content {
        h2 {
          -webkit-transform: translate(0px);
          transform: translate(0px);
        }
      }
    }
  }
}
ul.social:not(.social--small) {
  list-style: none;
  margin-top: 20px;
  padding: 0;

  li {
    display: inline;
    padding: 0;
    margin-right: 8px;

    a {
      background-color: #eae9e8;
      display: inline-block;
      color: $white;
      border-radius: $border-radius;
      @include transition(all .45s ease);

      i {
        background-color: transparent;
        display: inline-block;
        //margin-right: 4px;

        width: 48px;
        height: 48px;

        text-align: center;
        font-size: 30px;
      }

      &:hover {
        background-color: $primary;
      }
    }
  }
}
ul.social.social--small {
  display: inline-block;
  padding: 0;
  margin: 0 0 0 8px;
  list-style: none;
  li {
    display: inline;
    padding: 0;
    margin-right: 8px;
    a {
      color: $body-color;
      @include transition(all .45s ease);
      i {
        font-size: 16px;
      }
      &:hover {
        i {
          color: $primary;
        }
      }
    }
  }
}

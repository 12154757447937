//
// Additional button styles
//

.btn {
  white-space: normal;
  text-transform: uppercase;
  font-size: rem-calc(14);

  &.btn-sm {
    font-weight: 700;
  }

  &.btn-lg {
    font-size: $base-font-size;
  }

  &.btn-custom {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#006183+0,006183+100&0.8+1,0.5+100 */
    background: -moz-linear-gradient(top, rgba(0,97,131,0.8) 0%, rgba(0,97,131,0.8) 1%, rgba(0,97,131,0.5) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,97,131,0.8) 0%,rgba(0,97,131,0.8) 1%,rgba(0,97,131,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,97,131,0.8) 0%,rgba(0,97,131,0.8) 1%,rgba(0,97,131,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc006183', endColorstr='#80006183',GradientType=0 ); /* IE6-9 */

    padding: .875rem 1.4rem;
    color: #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
  }

  i {
    font-size: 85%;
    &:before {
      margin-left: 6px;
    }
  }
}

.btn-outline-primary {
  &:hover {
    //color: $brand-primary;
  }
}

.btn-no-hover {
  &:hover, &:active, &:focus {
    background-color: transparent;
    color: $white;
    box-shadow: none;
  }
}

@include media-breakpoint-up(md) {
  .btn + .btn {
    border-left: 0;
  }
}

.site-content {
  .btn {
    margin-bottom: 2rem;
  }
}
.topbar {
  position: relative;
  width: 100%;
  padding: 0;
  margin-top: 1rem;
  font-size: rem-calc(14);
  font-weight: $font-weight-bold;
  z-index: 999;
  text-align: center;

  ul {
    width: 100%;
    padding: 0;
    text-align: center;
    li {
      position: relative;
      a {
        padding: 5px 7px;
        color: $body-color;
        text-transform: uppercase;
      }
    }

    > li:hover, > li.active {
      > a {
        color: $primary;
      }
      > a:before {
        content: "";
        background-color: $primary;
        position: absolute;
        bottom: -3px;
        left: 0.9rem;
        height: 2px;
        width: calc(100% - 1.8rem);
      }
    }
  }

  a.btn-outline {
    border: 1px solid;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

@include media-breakpoint-up(md) {
  .topbar {
    ul {
      text-align: left;
    }
    a.btn-outline {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .topbar {
    position: absolute;
    top: 20px;
    left: 0;
    height: 50px;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 0;
    ul {
      margin: 0 auto;
      text-align: center;
      li {
        a {
          padding: 5px 14px;
        }
      }
    }
    a.btn-outline {
      right: 18px;
    }
  }
}

.list-inline {
  padding-right: 30px;
}
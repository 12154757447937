body {
  min-width: 320px;

  #page-wrapper {
    opacity: 0;
  }
  &.loading {
    #loading-bar-spinner {
      opacity: 1;
    }
  }
  &.loaded {
    #page-wrapper {
      opacity: 1;
    }
  }
}

a.underline {
  border-bottom: 1px solid;
}
.table {
  margin-bottom: 2rem;
}

.image {
  opacity: 0.8;
  pointer-events: none;

  &.image--gray {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.content-columns {
  h2, h3, h4, h5, h6 {
    padding-bottom: 1.8rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(#006183,0.2);
  }
}

@include media-breakpoint-up(lg) {
  body {
    &:after {
      content: "";
      background-color: rgba(255,255,255,0.5);
      width: 100%;
      height: calc(7vw + 220px);
      position: absolute;
      top: 0;
      opacity: 0;
      @include transition(opacity .2s ease);

    }
  }
}
@include media-breakpoint-up(xl) {
  body {
    &:after {
      height: calc(6vw + 220px);
    }
    &.dropdown-visible {
      &:after {
        opacity: 1;
      }
    }
  }
}
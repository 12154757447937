ul {
  margin-bottom: 2rem;
}
ul.list-inline {
  display: inline-block;
  margin-bottom: 2rem;
  li {
    display: inline;
  }
}

.content-page {
  ul:not(.radio-list):not(.pagination)  {
    padding-left: 24px;
    list-style: none;
    li {
      position: relative;
      &:before {
        content: "-";
        position: absolute;
        left: -24px;
      }
    }
  }
}
$body-bg: palette(white, base);
$body-color: palette(gray, darker);
$base-font-size: rem-calc(18);

$font-family-base:            'Open Sans', sans-serif;
$font-family-addition:        "DINPro",sans-serif;
$font-size-base:              1.125rem;
$font-size-lg:                ($font-size-base * 1.225);
$font-size-sm:                ($font-size-base * 0.9);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.675;

$h1-font-size:                rem-calc(34);
$h2-font-size:                1.875rem;
$h3-font-size:                1.5rem;
$h4-font-size:                1.2rem;
$h5-font-size:                $font-size-base * 1.05;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      1.2rem;
$headings-font-family:        $font-family-base;
$headings-font-weight:        $font-weight-light;
$headings-line-height:        1;
$headings-color:              $primary;

$display1-size:               rem-calc(44);
$display2-size:               rem-calc(34);
$display3-size:               2.375rem; //38px
$display4-size:               1.625rem; //26px

$display-line-height:         $headings-line-height*1.25;

$lead-font-size:              rem-calc(24);
$lead-font-weight:            $font-weight-light;

$small-font-size:             78%;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.2;

$border-radius:               rem-calc(17);
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                18rem;
$dropdown-padding-y:                2rem;
$dropdown-spacer:                   0rem;
$dropdown-bg:                       palette(white, base);
$dropdown-border-color:             palette(white, base);
$dropdown-border-width:             0;
$dropdown-box-shadow:               0;

$dropdown-link-color:               palette(grey, darker);
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            transparent;

$dropdown-link-active-color:        $primary;
$dropdown-link-active-bg:           transparent;

$dropdown-item-padding-x:           0rem;
$dropdown-item-padding-y:           0rem;

// Navs

$nav-link-padding-y:                0rem;
$nav-link-padding-x:                0.7rem;


// Navbar
$navbar-padding-y:                  0rem; //set to 0 because of mega menu
$navbar-padding-x:                  0rem;
$navbar-nav-link-padding-x:         1.6rem;

$navbar-light-color:                $body-color;
$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $primary;

$navbar-toggler-padding-y:          .25rem;
$navbar-toggler-padding-x:          0rem;
$navbar-light-toggler-border-color: transparent;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1300px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           36px;


// Links
//
// Style anchor elements.

$link-color:                palette(blue, dark);
$link-hover-decoration:     none;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   2rem;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
        0: 0,
        1: ($spacer * .125),
        2: ($spacer * .25),
        3: ($spacer * .5),
        4: ($spacer * 1),
        5: ($spacer * 2),
        6: ($spacer * 4)
), $spacers);


// Pagination
//
$pagination-disabled-color:         #cccccc;

$pagination-padding-y:              .3rem;
$pagination-padding-x:              .3rem;
//$pagination-padding-y-sm:           .25rem !default;
//$pagination-padding-x-sm:           .5rem !default;
//$pagination-padding-y-lg:           .75rem !default;
//$pagination-padding-x-lg:           1.5rem !default;
//$pagination-line-height:            1.25 !default;
//
$pagination-color:                  $body-color;
//$pagination-bg:                     $white !default;
$pagination-border-width:           0;
//$pagination-border-color:           $gray-300 !default;
//
//$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
//
//$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               transparent;
//$pagination-hover-border-color:     $gray-300 !default;
//
$pagination-active-color:           $body-color;
$pagination-active-bg:              transparent;
//$pagination-active-border-color:    $pagination-active-bg !default;
//
//$pagination-disabled-color:         $gray-600 !default;
//$pagination-disabled-bg:            $white !default;
//$pagination-disabled-border-color:  $gray-300 !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .45rem;
$btn-padding-x:               1.4rem;

$btn-padding-y-sm:            .5rem;
$btn-padding-x-sm:            2rem;

$btn-padding-y-lg:            1.025rem;
$btn-padding-x-lg:            4rem;
$btn-font-weight:             600;


// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius;
$btn-border-radius-sm:        $border-radius;

// Breadcrumbs

$breadcrumb-padding-y:              0rem;
$breadcrumb-padding-x:              0rem;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          1.5rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          #766e68;
$breadcrumb-active-color:           palette(white, base);
$breadcrumb-divider:                ">";


// Badges

$badge-font-size:                   1.0625rem;
$badge-font-weight:                 700;
$badge-padding-y:                   .75em;
$badge-padding-x:                   1.2em;
//custom css here, default settings in custom-variables.scss

ul.navbar-nav, .nav.subnav {
  .nav-link {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }
}
ul.navbar-nav {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  .dropdown-menu {
    position: relative;
  }
  > li {
    > a {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.navbar-toggler {
  padding: .5rem 2rem;
  //margin: 1.5rem auto 1.5rem auto;
  color: $primary!important;
  white-space: normal;
  text-transform: uppercase;
  font-size: .875rem;
  font-weight: 700;
  border: 1px solid $primary!important;
  .navbar-toggler-icon {
    background-size: 80% 80%;
    margin-left: 8px;
  }

}

.dropdown-menu {
  background-color: transparent;
  padding: 0.3rem 0 0.3rem 2.6rem;
  border-radius: 0;

  a {
    font-weight: normal;
    text-transform: uppercase;
  }
}

.dropdown .nav-link {
  &::after {
    content: "";
    background-image: url('../images/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 18px;
    height: 30px;
    position: absolute;
    top: 0;
    @include transition(transform .2s ease);
  }
}

.dropdown.show .nav-link {
  &::after {
    transform: rotate(180deg);
  }
}

@include media-breakpoint-down(lg) {
  .navbar {
    position: absolute;
    right: 18px;
    left: 0;
    top: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .navbar-collapse {
    z-index: 9;
    .navbar-nav {
      box-shadow: 0 4px 10px rgba(0,0,0,0.2);
      background-color: #fff;
      display: block;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    justify-content: flex-end;
  }
  ul.navbar-nav {
    .dropdown {
      position: static;
    }
  }
  .dropdown-menu {
    padding: 1.6rem;
  }
  ul.navbar-nav, .nav.subnav {
    > li:hover > a:before, > li.active > a:before, > li.show > a:before {
      content: "";
      background-color: $primary;
      height: 1.5px;
      width: calc(100% - 2.8rem);
      position: absolute;
      left: 1.4rem;
      bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    padding: 7vw 0 4vw;
  }
  .dropdown-menu {
    column-count: 2;
    &:after {
      content: "";
      background-color: #bfbfbf;
      position: absolute;
      top: 2.1rem;
      left: 48%;
      height: calc(100% - 4.2rem);
      width: 1px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1299px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
}

@media (min-width: 1300px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
  ul.navbar-nav, .nav.subnav {
    > li:hover > a:before, > li.active > a:before, > li.show > a:before {
      width: calc(100% - 3.2rem);
      left: 1.6rem;
    }
  }
}
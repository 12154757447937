.breadcrumb {

  li {
    @extend .breadcrumb-item;
    font-size: rem-calc(14);
    text-transform: uppercase;
    a {
      color: #8e8e8e;
    }
    &.last {
        color: $body-color;
      a {

      }
    }
  }
}
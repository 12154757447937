.block-wrapper {
  margin-bottom: 15px;
}
a:hover {
  .block {
    transform: scale(1.03);
  }
}
.block {
  position: relative;
  margin-bottom: 30px;
  @include transition(transform .45s ease);
  &--shadow {
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  }
  &--border {
    border: 10px solid $white;
  }
}

.block-info {
  position: absolute;
  padding: 15px;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  .download-button {
    margin-top: 16px;
  }
  @include linear-gradient(rgba(21,63,115,0), 60%, rgba(21,63,115,0.3), 99%);
}

.block-title {
  font-size: rem-calc(22);
  font-weight: $font-weight-light;
  line-height: 1.1;
  color: $white;
}

.block-description {
  font-size: rem-calc(16);
  color: $white;
}
@include media-breakpoint-up(sm) {
  .block-title {
    font-size: rem-calc(24);
  }
  .block-wrapper {
    margin-bottom: 36px;
  }
}
@include media-breakpoint-up(lg) {
  .block-title {
    font-size: rem-calc(28);
    + .block-description {
      margin-top: 4px;
    }
  }
  .block-description {
    font-size: rem-calc(18);
  }
}

@include media-breakpoint-up(xl) {
  .block {
    &--border {
      border-width: 15px;
    }
  }
  .block-info {
    padding: 30px;
  }
  .block-title {
    font-size: rem-calc(30);
    + .block-description {
      margin-top: 15px;
    }
  }
  .block-description {
    font-size: rem-calc(18);
  }
}

@media (min-width: 1800px) {
  .block-title {
    font-size: rem-calc(34);
  }
  .block-description {
    font-size: rem-calc(20);
  }
}

@media (min-width: 1921px) {
  .block-info {
    padding: 50px;
  }
}
#loading-bar-spinner {
  -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
  transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

  display: none;

  opacity: 0;

  &.spinner {
    left: 50%;
    top: 50%;
    position: fixed;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
  }
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border:  solid 8px transparent;
  border-top-color:  $brand-primary !important;
  border-left-color: $brand-primary !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}


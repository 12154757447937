.quicklink {
    position: relative;
    padding: 0;

    -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
    transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

    &.quicklink--black {
        background-color: $brand-primary;
    }

    .tile--content-wrapper {
        text-decoration: none;
        color: $brand-primary;
        display: inline-block;
        height: 100%;

        .tile--content {
            z-index: 9;
            overflow: hidden;
            position: relative;

            height: 80%;
            width: 80%;

            transition: background-color 200ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

            i {
                transform: translate(150%, 0%);
                transition: all 400ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
            }
        }
    }

    &:hover {
        opacity: 0.8;
    }
}
.timeline-wrapper {
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: 0 auto;
  max-width: 1920px;
}
ul.timeline {
  padding: 0;
  margin-top: 4rem;
  margin-bottom: 3rem;
  list-style: none;

  li {
    &:last-child {
      .list-item-intern {
        margin-bottom: 0;
      }
    }

    .badge {
      margin-top: 20px;
      float: left;
      backface-visibility: hidden;
    }

    .list-item-intern {
      padding-right: 30px;
      margin-bottom: 30px;
      position: relative;
      -webkit-flex-direction: column;
      flex-direction: column;
      &:before {
        background-color: $white;
        content: "";
        position: absolute;
        top: -20%;
        right: -1px;
        height: 140%;
        width: 1px;
      }
    }

    .list-item-left {
      position: relative;
      float: left;
      margin: 0 auto;
      width: 66px;
      min-width: 66px;
      @include transition(all .45s ease);
      backface-visibility: hidden;

      figure {
        margin-bottom: 8px;
      }

      span {
        backface-visibility: hidden;
      }
    }

    .list-item-right {
      width: 100%;
      @include transition(all .45s ease);
      backface-visibility: hidden;
    }

    .list-item-right-wrapper {
      background-color: $white;
      position: relative;
      padding: 15px 20px;
      @include transition(all .45s ease);

      &:after {
        content: "";
        position: absolute;
        top: 32px;
        right: -14px;
        margin-top: -15px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #fff;
      }
    }
  }

  h3 {
    margin-bottom: 0.2rem;
  }

  p {
    margin: 0;
  }

  a {
    color: $body-color;
    &:hover {
      .list-item-right-wrapper {
        box-shadow: 0 4px 10px rgba(0,0,0,0.2)
      }
      .list-item-left, .list-item-right {
        transform: scale(1.03);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  ul.timeline {
    li {
      .badge {
        float: left;
        width: 8em;
      }
      .list-item-left {
        margin: 0 25px 0 0;
        &:after {
          content: "";
          background-color: $white;
          position: absolute;
          top: 40px;
          left: 100%;
          height: 1px;
          width: 100%;
        }
      }
      &:nth-child(2n) {
        .list-item-intern {
          .badge {
            float: right;
          }
          padding-left: 30px;
          padding-right: 0;
          margin-left: 50%;
          text-align: right;

          -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;

          &:before {
            right: 0;
            left: 0;
          }

          .list-item-left {
            margin-right: 0;
            margin-left: 25px;
            &:after {
              left: auto;
              right: 100%;
            }
          }

          .list-item-right-wrapper {
            &:after {
              top: 40px;
              left: -30px;
              right: auto;
              border-top: 15px solid transparent;
              border-left: 15px solid transparent;
              border-right: 15px solid #fff;
            }
          }
        }
      }
      &:last-child {
        .list-item-intern {
          margin-bottom: 0;
        }
      }
      .list-item-intern {
        width: 50%;
        margin-bottom: -60px;
        -webkit-flex-direction: row;
        flex-direction: row;
      }
      .list-item-right-wrapper {
        &:after {
          top: 40px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  ul.timeline {
    li {
      .list-item-left {
        margin-right: 65px;
        width: 86px;
        min-width: 86px;
      }
      .list-item-right-wrapper {
        padding: 20px 30px;
      }
      &:nth-child(2n) {
        .list-item-intern {
          .list-item-left {
            margin-right: 0;
            margin-left: 65px;
          }
        }
      }
    }
  }
}
blockquote {
  float: right;
  margin: 0 0 1rem 1rem;
  color: $primary;
  font-size: $font-size-base * 1.33;
  font-weight: 300;
  line-height: 1.3367525;
}
@include media-breakpoint-up(md) {
  blockquote {
    width: 50%;
  }
}
.gallery {
    margin-bottom: 2rem;
    @include clearfix;
    transform: scale(1.025);
    overflow: hidden;

    .bg-img {
        width: 100%;
        padding-bottom: 100%;
        background-size: cover !important;
        background-position: center center !important;

        -webkit-backface-visibility: hidden;
        position: relative;
        overflow: hidden;

        -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
        transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
    }

    a {
        display: inline-block;
        float: left;
        width: 33.3333%;
        -webkit-transform: scale(0.9);
        transform: scale(0.92);

        &:hover {
            .bg-img {
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
            }
        }
    }

}

.gallery {
    &.one-image {
        a {
            width: 50%;

            @include media-breakpoint-up(lg) {
                width: 33.333%;
            }
        }
    }
    &.two-image {
        a {
            width: 50%;

            @include media-breakpoint-up(lg) {
                width: 33.333%;
            }
        }
    }
    &.three-image {
        a {
            width: 33.333%;
        }
    }
    &.more {
        a {
            width: 33.333%;

            @include media-breakpoint-up(md) {
                width: 25%;
            }
            @include media-breakpoint-up(lg) {
                width: 25%;
            }
        }
    }
}